'use client';

import { Dropzone, ExtFile } from '@files-ui/react';
import { IconCloudUp, IconX } from '@tabler/icons-react';
import { HTMLProps, useState } from 'react';
import { toast } from 'react-toastify';
import { Text } from '@/components/Text';
import { Button } from './form';
interface FilesUploadProps extends HTMLProps<HTMLInputElement> {
  label: string;
  error?: string;
  onUpdate: (_files: File[]) => void;
}
export const FilesUpload = (props: FilesUploadProps) => {
  const {
    label,
    error,
    onUpdate
  } = props;
  const [extFiles, setExtFiles] = useState<ExtFile[]>([]);
  const addFiles = (files: ExtFile[]) => {
    if (files.some(file => file.size && file.size > 30 * 1024 * 1024)) {
      toast.error('File size exceeds the limit of 30MB');
      return;
    }
    if (files.some(file => ![
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain', 'application/pdf', 'application/octet-stream', ''].includes(file.type || '') || !['pdf', 'txt', 'msg'].includes(file.name?.split('.').pop()?.toLowerCase()!))) {
      toast.error('Invalid file type. Only PDF and TXT files are allowed');
      return;
    }
    setExtFiles(files);
    onUpdate(files.map(file => file.file!));
  };
  const removeFile = (id: number | string | undefined) => {
    const filteredFiles = extFiles.filter(file => file.id !== id);
    setExtFiles(filteredFiles);
    onUpdate(filteredFiles.map(file => file.file!));
  };
  return <div data-sentry-component="FilesUpload" data-sentry-source-file="FilesUpload.tsx">
      <Text variant="label" data-sentry-element="Text" data-sentry-source-file="FilesUpload.tsx">{label}</Text>
      <div className="mt-1">
        <Dropzone onChange={addFiles} minHeight="95px" value={extFiles} accept="pdf/*,txt/*,msg/*" // docx/*"
      maxFiles={12} maxFileSize={2 * 1024 * 1024} label="Drag'n drop files here or click to browse" headerConfig={{
        customHeader: <></>
      }} footerConfig={{
        customFooter: <></>
      }} fakeUpload style={{
        fontSize: '14px',
        borderColor: error ? 'red' : undefined
      }} data-sentry-element="Dropzone" data-sentry-source-file="FilesUpload.tsx">
          <div className="flex w-full items-center px-5">
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center">
                <IconCloudUp size={30} data-sentry-element="IconCloudUp" data-sentry-source-file="FilesUpload.tsx" />
                <div className="ml-4 flex flex-col items-start">
                  <Text data-sentry-element="Text" data-sentry-source-file="FilesUpload.tsx">Drag and drop files here</Text>
                  <Text fontColor="gray" fontSize="sm" className="text-start" data-sentry-element="Text" data-sentry-source-file="FilesUpload.tsx">
                    Limit 30MB per file * PDF, TXT, MSG
                    {/* Limit 30MB per file * PDF, DOCX, TXT, MSG */}
                  </Text>
                </div>
              </div>
              <Button className="ml-4" type="button" variant="outline" data-sentry-element="Button" data-sentry-source-file="FilesUpload.tsx">
                Browse Files
              </Button>
            </div>
          </div>
        </Dropzone>
        {extFiles.length > 0 && <div className="mt-3 flex flex-col rounded-md border border-[#e0e6ed] dark:border-[#1b2e4b]">
            <div className="border-b border-[#e0e6ed] bg-gray-100 px-4 py-2.5 dark:border-[#1b2e4b]">
              <Text>File name</Text>
            </div>
            {extFiles.map(file => <div key={file.id} className="border-b border-[#e0e6ed] px-4 py-1 dark:border-[#1b2e4b]">
                <div key={file.id} className="flex items-center justify-between ">
                  <Text fontSize="sm" className="overflow-hidden">
                    {file.name}
                  </Text>
                  <Button variant="empty" onClick={() => {
              removeFile(file.id);
            }}>
                    <IconX size={16} />
                  </Button>
                </div>
              </div>)}
          </div>}
      </div>
      {error && <Text fontColor="error">{error}</Text>}
    </div>;
};