'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import { ReactNode, useEffect, useState } from 'react';
import { OpenAPI as OpenAPIConfig } from '../openapi/requests/core/OpenAPI';
const queryClient = new QueryClient({});
export const OpenApiProvider = ({
  children,
  managerUrl,
  noAuthRequired = false
}: {
  children: ReactNode;
  managerUrl: string;
  noAuthRequired?: boolean;
}) => {
  const {
    data: session,
    status
  } = useSession();
  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    OpenAPIConfig.BASE = managerUrl;
  }, [managerUrl]);
  useEffect(() => {
    if (noAuthRequired || status === 'authenticated' && session?.accessToken) {
      OpenAPIConfig.TOKEN = session?.accessToken;
      setIsInitialized(true);
    }
  }, [noAuthRequired, session, status]);
  if (!isInitialized) {
    return null;
  }
  return <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-component="OpenApiProvider" data-sentry-source-file="OpenApiProvider.tsx">{children}</QueryClientProvider>;
};