import React from 'react';
import { twMerge } from 'tailwind-merge';
interface CheckBoxProps extends React.HTMLProps<HTMLInputElement> {
  checked: boolean;
  onUpdate: (_value: boolean) => void;
}
export const CheckBox = (props: CheckBoxProps) => {
  const {
    checked,
    onUpdate,
    className
  } = props;
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    onUpdate && onUpdate(event.target.checked);
  };
  return <input type="checkbox" className={twMerge(className, 'form-checkbox')} checked={checked} onChange={handleInputChange} data-sentry-component="CheckBox" data-sentry-source-file="CheckBox.tsx" />;
};