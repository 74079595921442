import Link from 'next/link';
import { Text } from './Text';
type BreadCrumbsProps = {
  breadcrumbs: {
    label: string;
    href?: string;
  }[];
};
export const BreadCrumbs = (props: BreadCrumbsProps) => {
  const {
    breadcrumbs
  } = props;
  return <div className="flex items-center" data-sentry-component="BreadCrumbs" data-sentry-source-file="BreadCrumbs.tsx">
      <Link href="/" data-sentry-element="Link" data-sentry-source-file="BreadCrumbs.tsx">
        <Text fontSize="sm" data-sentry-element="Text" data-sentry-source-file="BreadCrumbs.tsx">Home</Text>
      </Link>
      {breadcrumbs.map((breadcrumb, index) => <div key={`breadcrumb_${index}`}>
          {index + 1 < breadcrumbs.length ? <Link href={breadcrumb.href!}>
              <Text className="ml-4">&gt;</Text>
              <Text className="ml-4">{breadcrumb.label}</Text>
            </Link> : <>
              <Text className="ml-4">&gt;</Text>
              <Text className="ml-4" fontColor="gray" fontSize="sm">
                {breadcrumb.label}
              </Text>
            </>}
        </div>)}
    </div>;
};